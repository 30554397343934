import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  HalvedContent,
  ContentBox,
  Paragraphs,
  Grid,
  Dialog
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/aktuelles/ratgeber/berufsunfaehigkeit';

const RatgeberBerufsunfaehigkeitPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
  >
    <section>
      <SectionHead headline={content.intro.headline} centered>
        <p>{content.intro.paragraph}</p>
      </SectionHead>
      <HalvedContent align="center">
        <BackgroundImage fluid={data.manWithNewspaper.childImageSharp.fluid} />
        <ContentBox background="gray-blue" headline={content.loesung.headline}>
          <Paragraphs items={content.loesung.paragraphs} />
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <ContentBox
        background="light-blue"
        headline={content.vorteile.headline}
        single
      >
        <Paragraphs items={content.vorteile.paragraphs} />
      </ContentBox>
      <SectionHead headline={content.vorteile.subHeadline} />
      <Grid>
        {content.vorteile.cards.map((card, index) => (
          <Card key={index}>
            <CardHeader
              headline={card.headline}
              color="dark-blue"
              smallFontSize
            >
              <card.icon />
            </CardHeader>
            <p>{card.paragraph}</p>
            <Dialog
              buttonText={card.dialog.buttonText}
              buttonVariant={card.dialog.buttonVariant}
              title={card.dialog.titleoka}
            >
              <Paragraphs items={card.dialog.paragraphs} />
              {card.dialog.listItems &&
                card.dialog.listItems.map((listItem, index2) => (
                  <li key={index2}>{listItem}</li>
                ))}
            </Dialog>
          </Card>
        ))}
      </Grid>
    </section>
  </MainLayout>
);

export default RatgeberBerufsunfaehigkeitPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-glases-and-white-shirt.jpg" }
    ) {
      ...image
    }
    manWithNewspaper: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-newspaper.jpg" }
    ) {
      ...image
    }
  }
`;
