import PeopleCircleIcon from 'src/assets/icons/people-circle.svg';
import MedicalNotebookIcon from 'src/assets/icons/medical-notebook.svg';
import PiggyBankIcon from 'src/assets/icons/piggy-bank.svg';

const content = {
  title: 'Ratgeber - Berufsunfähigkeit',
  headvisualHeadline: 'Berufsunfähigkeitsschutz clever nutzen',
  intro: {
    headline: 'Clever sein und den Berufsunfähigkeitsschutz in der bAV nutzen!',
    paragraph:
      'Berufsunfähigkeit kann jeden treffen. Niemand ist vor Krankheit oder Unfall geschützt. Nahezu 25 % der Arbeitnehmer sind davon betroffen, egal in welchen Beruf – ob Handwerker oder Sekretärin. Aus diesem Grund ist es so wichtig für den Ernstfall vorzusorgen.'
  },
  loesung: {
    headline: 'Die Lösung - BU-Schutz in der bAV',
    paragraphs: [
      'Die Absicherung der Berufsunfähigkeit funktioniert dabei sehr unkompliziert: Sie zahlen für sich bzw. Ihren Mitarbeiter regelmäßig in die betriebliche Altersversorgung (bAV) ein. Das geschieht über die sogenannte Gehaltsumwandlung.',
      'Auf Grund der Steuerförderung der Gehaltsumwandlung sind die existenziellen Leistungen einer BU schon zu erschwinglichen Beiträgen zu haben. Somit eine Win - Win - Situation für alle Beteiligten – für Sie als Geschäftsführer, für Ihr Unternehmen und für Ihre Mitarbeiter. Abschließen können den BU - Schutz nämlich nicht nur ihre Mitarbeiter, sondern auch Sie als Gesellschafter - Geschäftsführer (GGF).'
    ]
  },
  vorteile: {
    headline: 'Viele Vorteile für Ihre Angestellten.',
    paragraphs: [
      'Für Angestellte ist der BU - Schutz ein wichtiger Baustein ihrer finanziellen Absicherung, um die Konsequenzen eines Verlustes der Berufsfähigkeit abzufangen.',
      'Viele Mitarbeiter würden gerne privat eine BU - Versicherung abschließen, scheitern aber z.B. an den hohen Hürden der Gesundheitsprüfung, oder die Beiträge sind ihnen zu teuer. Dadurch, dass Sie als Arbeitgeber die BU im Rahmen der bAV anbieten, machen Sie die BU für Ihre Mitarbeiter erreichbar und erschwinglich.'
    ],
    subHeadline:
      'Die wichtigsten Vorteile warum die BU gerade in der bAV ihren Platz hat:',
    cards: [
      {
        icon: PeopleCircleIcon,
        headline: 'Mitarbeiterbindung.',
        paragraph:
          'Der Kampf um Top-Arbeitskräfte schwelt am Markt. Fachkräfte können sich heute ihren Arbeitgeber aussuchen.',
        dialog: {
          buttonText: 'Mehr lesen',
          buttonVariant: 'gray-blue',
          title: 'Mitarbeiterbindung',
          paragraphs: [
            'Sie wählen die Firmen für sich aus, die zu ihnen passen, sie motivieren und ein gutes Leistungspaket für sie bereitstellen.',
            'Sorgen Sie dafür, dass ihr Leistungspaket stimmt, indem Sie eine BU in der bAV für ihre Angestellten anbieten. Ihre Angestellten können sich dadurch einige Vorteile für einen preisgünstigen BU-Schutz sichern und Sie als Arbeitgeber positionieren sich als verantwortungsbewusstes, modernes Unternehmen.'
          ]
        }
      },
      {
        icon: MedicalNotebookIcon,
        headline: 'Vereinfachte Gesundheitsprüfung.',
        paragraph:
          'Wird der BU-Schutz mit einem Produkt der bAV abgeschlossen, muss nur eine vereinfachte Gesundheitsprüfung erfolgen.',
        dialog: {
          buttonText: 'Mehr lesen',
          buttonVariant: 'gray-blue',
          title: 'Vereinfachte Gesundheitsprüfung.',
          paragraphs: [
            'Für diese füllt der Arbeitgeber eine sogenannte Dienstobliegenheitserklärung aus, durch die er zum einen bestätigt, dass der Mitarbeiter weder eine schwere Krankheit noch eine Behinderung hat und zum anderen, dass der Arbeitnehmer'
          ],
          listItems: [
            'Arbeitsfähig ist und',
            'die letzten zwei Jahre nicht länger als 2 Wochen am Stück krank war.'
          ]
        }
      },
      {
        icon: PiggyBankIcon,
        headline: 'Kosten sparen.',
        paragraph:
          'Der zweite Vorteil der BU in der bAV Kostenersparnis begründet. ',
        dialog: {
          buttonText: 'Mehr lesen',
          buttonVariant: 'gray-blue',
          title: 'Kosten sparen.',
          paragraphs: [
            'Dies liegt daran, dass die Beiträge in der bAV steuerlich gefördert werden. Bis zu 8 % der Beitragsbemessungsgrenze (BBG) kann lohnsteuerfrei und 4 % der BBG sozialversicherungsvertrag kann in einen BU-Vertrag in der bAV eingezahlt werden.'
          ]
        }
      }
    ]
  }
};

export default content;
